<div class="header">
    <h1 *ngIf="model.h_status == 1">{{'Quotation'| translate}}</h1>
    <h1 *ngIf="model.h_status == 2" class="flex">
        <span>{{'INVOICE'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <h1 *ngIf="model.h_status == 3" class="flex">
        <span>{{'TAX INVOICE'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <h1 *ngIf="model.h_status == 4" class="flex">
        <span>{{'DELIVERY NOTE'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <h1 *ngIf="model.h_status == 5" class="flex">
        <span>{{'RECEIPT'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <h1 *ngIf="model.h_status == 6" class="flex">
        <span>{{'BILLING NOTE'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <h1 *ngIf="model.h_status == 7" class="flex">
        <span>{{'Receipt / Tax Invoice'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <h1 *ngIf="model.h_status == 8" class="flex">
        <span>{{'Purchase Order'| translate}}</span>
        <span class="float-end" *ngIf="!copy">{{'ORIGINAL'| translate}}</span>
        <span class="float-end" *ngIf="copy">{{'COPY'| translate}}</span>
    </h1>
    <!-- <hr> -->
    <div class="box-header">
        <div class="box-image" *ngIf="model?.compenyItems?.contact_img_path">
            <img [src]="model?.compenyItems?.contact_img_path" alt="">
        </div>
        <p class="text-header w-100 ms-2">{{model.compenyItems.contact_fullname}}

            <span *ngIf="model.compenyItems.branch_no == '00000'">
                ( {{'Headquarters'| translate}} )
            </span>
            <span *ngIf="model.compenyItems.branch_no != '00000'">
                ( {{'Branches'| translate}} {{model.compenyItems.branch_no}} )
            </span> <br>

            {{model.compenyItems.address_detail}}
            {{model.compenyItems.district}}
            {{model.compenyItems.city}}
            {{model.compenyItems.state}}
            {{model.compenyItems.zip}}

            <br>
            <span *ngIf="model?.compenyItems?.contact_tax_id">
                {{'Tax ID'| translate}} :
                {{model.compenyItems.contact_tax_id || '-'}}
            </span>
            <br>
            <span *ngIf="model.compenyItems.telephones.length > 0">Tel : </span>
            <ng-container *ngFor="let tel of model.compenyItems.telephones; let i = index">
                <span>+{{tel.country_code}}{{tel.phone_number}}</span>
                <span *ngIf="model.compenyItems.telephones.length != i+1">, </span>
            </ng-container>
            <span *ngIf="model?.compenyItems?.email"> {{'Email'| translate}} : {{model.compenyItems.email}}</span>
        </p>
        <p class="text-header text-end w-50">
            <span *ngIf="model.h_status == 1">{{'Quotation'| translate}}</span>
            <span *ngIf="model.h_status == 8">{{'Purchase Order'| translate}}</span> {{'No.'| translate}} :
            {{model.quotationItems.quot_no
            ||
            model.quotationItems.no || model.quotationItems.po_no }}
            <br>
            {{'Date'| translate}} : {{model.quotationItems.create_date_full || model.quotationItems.create_date |
            date:'dd/MM/yyyy'}}
            <ng-container *ngIf="model.h_status == 2 || model.h_status == 3 || model.h_status == 4">
                <br>
                {{'Ref to Sales Order'| translate}} : {{ model.quotationItems.convert_from_ref_no}}
                <br>
                {{'Customer P/O'| translate}} : {{ model.quotationItems.customer_po}}
                <br>
            </ng-container>
            <ng-container *ngIf="model.h_status == 8">
                <ng-container *ngIf="model.quotationItems.convert_from_ref_no">
                    <br>
                    {{'Ref to PR'| translate}} : {{ model.quotationItems.convert_from_ref_no}}
                </ng-container>
                <ng-container *ngIf="model.quotationItems.refference_code">
                    <br>
                    {{'Vendor Quot'| translate}} : {{ model.quotationItems.refference_code}}
                </ng-container>
                <br>
            </ng-container>
            <br>
            <span *ngIf="model.h_status == 5 ||  model.h_status == 7">{{'Ref to Invoice No'| translate}} : {{
                model.quotationItems.convert_from_ref_no}}</span>

        </p>

    </div>
    <!-- <hr> -->
    <!-- Vendor -->
    <div class="box-custumer mt-2" *ngIf="model.h_status == 8">
        <div class="headers b pt-2">{{'Vendor'| translate}}</div>
        <p class="text-header mt-2">{{model.vendorItems.contact_fullname}} <span
                *ngIf="model.vendorItems.branch_no == '00000'">
                ( {{'Headquarters'| translate}} )
            </span>
            <span *ngIf="model.vendorItems.branch_no != '00000'">
                ( {{'Branches'| translate}} {{model.vendorItems.branch_no}} )
            </span> <br>

            {{model.vendorItems.address_detail}}
            {{model.vendorItems.district}}
            {{model.vendorItems.city}}
            {{model.vendorItems.state}}
            {{model.vendorItems.zip}}

            <br>
            <span *ngIf="model?.vendorItems?.contact_tax_id">
                {{'Tax ID'| translate}} :
                {{model.vendorItems.contact_tax_id}}
            </span>
            <br *ngIf="model?.vendorItems?.contact_tax_id">
            <span *ngIf="model.vendorItems.employee_fullname">
                {{'Contact'| translate}} :
                {{model.vendorItems.employee_fullname}}
            </span>

            <span *ngIf="model.vendorItems.employee_phone_number"> {{'Tel'| translate}} :
                +{{model.vendorItems.employee_country_code}}{{model.vendorItems.employee_phone_number}}</span>
            <span *ngIf="model.vendorItems.employee_corp_phone_number">
                <span *ngIf="!model.vendorItems.employee_phone_number">, {{'Tel'| translate}} :</span>
                +{{model.vendorItems.employee_corp_country_code}}{{model.vendorItems.employee_corp_phone_number}} ext
                {{model.vendorItems.employee_corp_phone_number_ext}}</span>
            <span *ngIf="model.vendorItems.employee_email"> {{'Email'| translate}} :
                {{model.vendorItems.employee_email}}</span>
        </p>
    </div>
    <!-- Customer -->
    <div class="box-custumer mt-2" *ngIf="model.h_status != 4 && model.h_status != 8">
        <div class="headers b pt-2">{{'Customer'| translate}}</div>
        <p class="text-header mt-2">{{model.contactItems.contact_fullname}} <span
                *ngIf="model.contactItems.branch_no == '00000'">
                ( {{'Headquarters'| translate}} )
            </span>
            <span *ngIf="model.contactItems.branch_no != '00000' && model.contactItems.branch_no != null">
                ( {{'Branches'| translate}} {{model.contactItems.branch_no}} )
            </span> <br>

            {{model.contactItems.address_detail}}
            {{model.contactItems.district}}
            {{model.contactItems.city}}
            {{model.contactItems.state}}
            {{model.contactItems.zip}}

            <br>
            <span *ngIf="model?.contactItems?.contact_tax_id">
                {{'Tax ID'| translate}} :
                {{model.contactItems.contact_tax_id}}
            </span>
            <br *ngIf="model?.contactItems?.contact_tax_id">
            <span *ngIf="model.contactItems.employee_fullname">
                {{'Contact'| translate}} :
                {{model.contactItems.employee_fullname}}
            </span>

            <span *ngIf="model.contactItems.employee_phone_number"> {{'Tel'| translate}} :
                +{{model.contactItems.employee_country_code}}{{model.contactItems.employee_phone_number}}</span>
            <span *ngIf="model.contactItems.employee_corp_phone_number">
                <span *ngIf="!model.contactItems.employee_phone_number">, {{'Tel'| translate}} :</span>
                +{{model.contactItems.employee_corp_country_code}}{{model.contactItems.employee_corp_phone_number}} ext
                {{model.contactItems.employee_corp_phone_number_ext}}</span>
            <span *ngIf="model.contactItems.employee_email"> {{'Email'| translate}} :
                {{model.contactItems.employee_email}}</span>
        </p>
    </div>
    <div class="row" *ngIf="model.h_status == 4 || model.h_status == 8">
        <div class="col-6 break">
            <div class="box-custumer text-in mt-2">
                <div class="headers b pt-2">
                    <span *ngIf="model.h_status == 4">{{'Customer'| translate}}</span>
                    <span *ngIf="model.h_status == 8">{{'Billing address'| translate}}</span>
                </div>
                <p class="text-header mt-2">
                    {{model.contactItems.contact_fullname}}
                    <span *ngIf="model.contactItems.branch_no == '00000'">
                        ( {{'Headquarters'| translate}} )
                    </span>
                    <span *ngIf="model.contactItems.branch_no != '00000'">
                        ( {{'Branches'| translate}} {{model.contactItems.branch_no}} )
                    </span> <br>

                    {{model.contactItems.address_detail}}
                    {{model.contactItems.district}}
                    {{model.contactItems.city}}
                    {{model.contactItems.state}}
                    {{model.contactItems.zip}}
                    <span *ngIf="model.h_status != 8">
                        <br>
                        {{'Tax ID'| translate}} :
                        {{model.contactItems.contact_tax_id}}
                    </span>
                    <br>
                    <span *ngIf="model.contactItems.employee_fullname">
                        {{'Contact'| translate}} :
                        {{model.contactItems.employee_fullname}}
                    </span>
                    <br>
                    <span *ngIf="model.contactItems.employee_phone_number"> {{'Tel'| translate}} :
                        +{{model.contactItems.employee_country_code}}{{model.contactItems.employee_phone_number}}</span>
                    <span *ngIf="model.contactItems.employee_corp_phone_number">
                        <span *ngIf="!model.contactItems.employee_phone_number">, {{'Tel'| translate}} :</span>
                        +{{model.contactItems.employee_corp_country_code}}{{model.contactItems.employee_corp_phone_number}}
                        ext
                        {{model.contactItems.employee_corp_phone_number_ext}}</span>
                    <br>
                    <span *ngIf="model.contactItems.employee_email"> {{'Email'| translate}} :
                        {{model.contactItems.employee_email}}</span>
                </p>
            </div>
        </div>
        <div class="col-6 break">
            <div class="box-custumer text-in mt-2">
                <div class="headers b pt-2">
                    <span *ngIf="model.h_status != 8">{{'Delivery Address'| translate}}</span>
                    <span *ngIf="model.h_status == 8">{{'Shipping address'| translate}}</span>
                </div>
                <p class="text-header mt-2">
                    <span class="mt-1"> {{model.addressItems.contact_fullname}}</span>
                    <span *ngIf="model.addressItems.branch_no == '00000'">
                        ( {{'Headquarters'| translate}} )
                    </span>
                    <span *ngIf="model.addressItems.branch_no && model.addressItems.branch_no != '00000'">
                        ( {{'Branches'| translate}} {{model.contactItems.branch_no}} )
                    </span> <br>

                    {{model.addressItems.address_detail}}
                    {{model.addressItems.district}}
                    {{model.addressItems.city}}
                    {{model.addressItems.state}}
                    {{model.addressItems.zip}}

                    <br>
                    <span *ngIf="model.addressItems.employee_fullname">
                        {{'Contact'| translate}} :
                        {{model.addressItems.employee_fullname}}
                    </span>
                    <br>
                    <span *ngIf="model.addressItems.employee_phone_number"> {{'Tel'| translate}} :
                        +{{model.addressItems.employee_country_code}}{{model.addressItems.employee_phone_number}}</span>
                    <span *ngIf="model.addressItems.employee_corp_phone_number">
                        <span *ngIf="!model.addressItems.employee_phone_number">, {{'Tel'| translate}} :</span>
                        +{{model.addressItems.employee_corp_country_code}}{{model.addressItems.employee_corp_phone_number}}
                        ext
                        {{model.addressItems.employee_corp_phone_number_ext}}</span>
                    <br>
                    <span *ngIf="model.addressItems.employee_email"> {{'Email'| translate}} :
                        {{model.addressItems.employee_email}}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!model.quotationItems.delivery_self && model.h_status == 4">
        <div class="col-12">
            <div class="box-custumer mt-2">
                <div class="headers b pt-2">{{'Carrier'| translate}}</div>
                <span style="font-size: 10px;"> {{model.quotationItems.delivery_self_note || '-'}}</span>
            </div>
        </div>
    </div>

    <div class="box-valid mt-2">
        <table class="table headers" *ngIf="model.h_status == 1">
            <tr>
                <th class="f color">{{'Valid Until'| translate}}</th>
                <th class="f color">{{'Payment Term'| translate}}</th>
                <th class="f color">{{'Delivery Time'| translate}}</th>
                <th class="f color">{{'Warranty'| translate}}</th>
            </tr>
            <tr>
                <td class="f">{{model.quotationItems.validuntill | date:'dd/MM/yyyy'}} </td>
                <td class="f">{{model.quotationItems.payment_term}}</td>
                <td class="f">{{model.quotationItems.delivery_time}}</td>
                <td class="f">{{model.quotationItems.refference_code}}</td>
            </tr>
        </table>
        <table class="table headers" *ngIf="model.h_status == 2">
            <tr>
                <th class="f color">{{'Payment Term'| translate}}</th>
                <th class="f color">{{'Due Date'| translate}}</th>
            </tr>
            <tr>
                <td class="f">{{model.quotationItems.payment_term}}</td>
                <td class="f" *ngIf="model.quotationItems.due_date">
                    {{model.quotationItems.due_date |
                    date:'dd/MM/yyyy'}}</td>
                <td class="f" *ngIf="!model.quotationItems.due_date" style="color: red;">*กำลังแก้ไข</td>
            </tr>
        </table>
        <div class="box-header-content" *ngIf="model.h_status == 5 || model.h_status == 7">
            <ng-container *ngIf="!model.quotationItems.receipt_pay_has">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <input type="checkbox">
                                <span class="ms-2">{{'CASH'| translate}}</span>
                                <br>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <input type="checkbox">
                                <span class="ms-2">{{'CHEQUE'| translate}}</span>
                                <br>
                            </td>
                            <td>

                                <span class="ms-3">{{'NO.'| translate}}. ................................</span>
                                <span class="ms-3">{{'BANK'| translate}}.........................................</span>
                                <span class="ms-3">{{'BRANCH'|
                                    translate}}........................................</span>
                                <span class="ms-3">{{'DATE'| translate}}...................................</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="checkbox">
                                <span class="ms-2">{{'DIRECT DEPOSIT/TRANSFER'| translate}}</span>
                                <br>
                            </td>
                            <td>

                                <span class="ms-3">{{'NO.'| translate}}. ................................</span>
                                <span class="ms-3">{{'BANK'| translate}}.........................................</span>
                                <span class="ms-3">{{'BRANCH'|
                                    translate}}........................................</span>
                                <span class="ms-3">{{'DATE'| translate}}...................................</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <ng-container *ngIf="model.quotationItems.receipt_pay_has">
                <p class="mt-2" *ngFor="let mt of model.quotationItems.receipt_payment_methods">
                    <ng-container *ngIf="mt.receipt_pay_type != 'Cheque'">
                        <span> {{mt.receipt_pay_type | translate}}</span>
                        <span> {{mt.receipt_pay_bank}}</span>
                        <span> {{mt.receipt_pay_branch}}</span>
                        <span *ngIf="mt.receipt_pay_no"> {{'Ref'| translate}}: {{mt.receipt_pay_no}}</span>
                        <span> {{'Amount'| translate}}: {{mt.receipt_pay_amount | number:'1.2-2'}}</span>
                        <span *ngIf="mt.receipt_pay_date"> {{'Date'| translate}} {{mt.receipt_pay_date |
                            date:'dd/MM/yyyy:HH:mm:ss'}}</span>

                    </ng-container>
                    <ng-container *ngIf="mt.receipt_pay_type == 'Cheque'">
                        <span> {{mt.receipt_pay_type | translate}}</span>
                        <span> {{'No.'| translate}}. {{mt.receipt_pay_no}}</span>
                        <span> {{mt.receipt_pay_bank}}</span>
                        <span> {{mt.receipt_pay_branch}}</span>
                        <span> {{'Amount'| translate}}: {{mt.receipt_pay_amount | number:'1.2-2'}}</span>
                        <span> {{'Date'| translate}} {{mt.receipt_pay_date | date:'dd/MM/yyyy'}}</span>

                    </ng-container>
                </p>
            </ng-container>
        </div>
        <!-- <hr> -->
    </div>
</div>